import tw, { styled } from 'twin.macro';

interface WrapperProps {
  contactModalOpen: boolean;
}

export const OverflowWrapper = styled.div<WrapperProps>`
  ${tw`h-screen`}
  ${({ contactModalOpen }) => (contactModalOpen ? tw`overflow-hidden` : tw`overflow-visible`)}
`;
