import tw, { styled } from 'twin.macro';
import { Link } from 'gatsby';

import Container from '../Container';
import { getTwStyleForColor } from '@utils';

interface StyledNavigationProps {
  color?: string;
}

//export const StyledMenu = tw.div`fixed pt-36 px-24 pb-20 top-0 right-0 h-screen z-30`;

export const NavigationBackground = styled.div<StyledNavigationProps>`
  ${tw`fixed z-20 block w-full`};
  ${({ color }) => getTwStyleForColor(color)}
  height: 100px;
`;

export const StyledNavigation = styled.div<StyledNavigationProps>`
  ${tw`fixed z-40 block w-full`};
  height: 100px;
`;

interface StyledMenuProps {
  visible: boolean;
}

export const StyledMenu = styled.div<StyledMenuProps>`
  ${tw`relative flex flex-col items-end z-30`}
  ${({ visible }) => (visible ? tw`block` : tw`hidden`)}
`;

export const NavigationInner = tw(
  Container,
)`relative h-full w-full flex justify-between items-center`;

export const NavigationInnerLogo = tw(Link)`block`;

export const NavigationInnerHamburger = tw.div`w-12 h-12 relative`;
