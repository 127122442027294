import { StyledHeading } from './styled';
import { useInView } from 'react-intersection-observer';
import * as React from 'react';

export type HeadingTypes = 'service' | 'solution';

interface HeadingProps {
  children: React.ReactNode;
  type: HeadingTypes;
}

const Heading: React.FunctionComponent<HeadingProps> = ({ type, children }) => {
  const { ref, inView } = useInView();

  return (
    <StyledHeading type={type} ref={ref} inView={inView}>
      {children}
    </StyledHeading>
  );
};

export default Heading;
