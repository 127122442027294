import tw, { styled } from 'twin.macro';

export const Wrapper = tw.div`w-full flex flex-row mb-16`;

export const Subheading = tw.p`tracking-wider text-sm font-bold mb-4`;

export const Icon = styled.img`
  ${tw`block mr-6 h-10`}
  min-width: 4rem;
`;
