import React from 'react';

import Contact from '@components/Contact';
import { Wrapper, ColumnHeading, FooterContent } from './styled';

interface FooterColumnProps {
  heading: string;
  content?: string;
  cta: string;
  highlight: boolean;
}

const FooterColumn: React.FC<FooterColumnProps> = ({ heading, children, cta, highlight }) => {
  return (
    <Wrapper>
      <ColumnHeading>{heading}</ColumnHeading>
      <FooterContent>{children}</FooterContent>
      <Contact withBorder={highlight} href={`mailto:${cta}`}>
        {cta}
      </Contact>
    </Wrapper>
  );
};

export default FooterColumn;
