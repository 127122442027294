import React from 'react';
import Content from '@components/Content';
import Heading from '../shared/Heading';
import { Wrapper } from './styled';

interface ServiceProps {
  title: string;
  children: React.ReactNode;
}

const Service: React.FC<ServiceProps> = ({ title, children }) => (
  <Wrapper>
    <Heading type="service">{title}</Heading>
    <Content>{children}</Content>
  </Wrapper>
);

export default Service;
