import tw, { TwStyle } from 'twin.macro';

const getTwStyleForColor = (color?: string): TwStyle => {
  switch (color) {
    case 'blue':
      return tw`bg-indigo`;
    case 'black':
      return tw`bg-black`;
    case 'white':
      return tw`bg-white`;
    default:
      return tw`bg-yellow`;
  }
};

export default getTwStyleForColor;
