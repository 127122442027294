import tw, { styled } from 'twin.macro';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export const Wrapper = styled(AnchorLink)`
  ${tw`font-sans text-current cursor-pointer text-4xl mb-2 font-bold no-underline hover:text-yellow translate-x-full opacity-0`}

  transform: translateX(100%);
  animation: slide-in 0.7s forwards;
  ${({ delay }) => `animation-delay: ${delay}s;`}

  @keyframes slide-in {
    0% {
      ${tw`opacity-0`}
    }
    100% {
      ${tw`opacity-100`}
      transform: translateX(0%);
    }
  }
`;
