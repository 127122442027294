import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Wrapper } from './styled';

interface ContactProps {
  children: React.ReactNode;
  withBorder: boolean;
  href: string;
}

const Contact: React.FC<ContactProps> = ({ children, withBorder, href }) => {
  const { ref, inView } = useInView();

  return (
    <Wrapper withBorder={withBorder} href={href} inView={inView} ref={ref}>
      {children}
    </Wrapper>
  );
};

export default Contact;
