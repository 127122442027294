import React from 'react';
import { Squeeze as Hamburger } from 'hamburger-react';
import { StyledHamburgerIcon } from './styled';

export type HamburgerColors = 'white' | 'black';

export interface HamburgerIconProps {
  crossed: boolean;
  color: HamburgerColors;
  handleClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const HamburgerIcon: React.FC<HamburgerIconProps> = ({
  crossed = false,
  color = 'black',
  handleClick,
}) => (
  <StyledHamburgerIcon>
    <Hamburger
      duration={0.2}
      size={20}
      toggle={handleClick}
      color={color}
      toggled={crossed}
      label="button"
    />
  </StyledHamburgerIcon>
);
export default HamburgerIcon;
