import React from 'react';
import { nanoid } from 'nanoid';
import HamburgerIcon from '@components/HamburgerIcon';

import { Context } from '@components/ContextWrapper';
import {
  MenuWrapper,
  MenuInner,
  LinkCol,
  SocialLinks,
  MenuInnerWrapper,
} from '@components/Navigation/Menu';
import Contact from '@components/Contact';
import NavLink, { LinkProps } from '@components/Navigation/Link';
import Logo from './Logo';

import {
  StyledMenu,
  StyledNavigation,
  NavigationInner,
  NavigationInnerLogo,
  NavigationInnerHamburger,
  NavigationBackground,
} from './styled';

export interface NavigationProps {
  data: {
    links: LinkProps[];
    socialLinks: LinkProps[];
  };
}

const Navigation: React.FC = ({ data }) => {
  const {
    menuOpen,
    contactModalOpen,
    headerColor,
    headerIconColor,
    toggleContactModal,
    toggleMenu,
  } = React.useContext(Context);

  const hamburgerColor = menuOpen ? 'white' : headerIconColor;
  const isCrossed = menuOpen || contactModalOpen;
  const handleClick = () => {
    if (contactModalOpen) {
      toggleContactModal();
    } else {
      toggleMenu();
    }
  };

  return (
    <>
      {/*Background needs to have lower z-index than section background, so that section color is visible*/}
      <NavigationBackground color={headerColor} />
      <StyledNavigation>
        <NavigationInner>
          <NavigationInnerLogo to="/">
            <Logo color={headerIconColor} />
          </NavigationInnerLogo>
          {typeof data !== 'undefined' && (
            <NavigationInnerHamburger>
              <HamburgerIcon color={hamburgerColor} crossed={isCrossed} handleClick={handleClick} />
            </NavigationInnerHamburger>
          )}
        </NavigationInner>
        {/* Links should go into StyledMenu */}
        {typeof data !== 'undefined' && (
          <StyledMenu visible={menuOpen}>
            <MenuInner>
              <MenuInnerWrapper>
                <LinkCol>
                  {data.links.map((link, index: number) => (
                    <NavLink href={link.href} delay={0.4 + 0.04 * index} key={nanoid()}>
                      {link.name}
                    </NavLink>
                  ))}
                </LinkCol>
                {/* TODO: Add social links to the navigation */}
                <SocialLinks>
                  {data.socialLinks.map((link) => (
                    <Contact href={link.href} withBorder={true} key={nanoid()}>
                      {link.name}
                    </Contact>
                  ))}
                </SocialLinks>
              </MenuInnerWrapper>
            </MenuInner>
          </StyledMenu>
        )}
        {/* Animation/style wrapper */}
        <MenuWrapper onClick={handleClick} visible={menuOpen} />
      </StyledNavigation>
    </>
  );
};
export default Navigation;
