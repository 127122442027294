import tw, { styled } from 'twin.macro';

interface WrapperProps {
  withBorder: boolean;
  inView: boolean;
}

export const Wrapper = styled.a<WrapperProps>`
  ${tw`relative font-body text-white w-fit-content no-underline pr-4 hover:text-yellow visited:text-white`}

  &:hover {
    &:after {
      ${tw`bg-yellow`}
    }
  }

  &:after {
    ${tw`absolute top-6 left-0 h-3.5 bg-white transition-all duration-1000`}
    content: "";
    ${({ withBorder, inView }) => (withBorder && inView ? tw`w-full` : tw`w-0`)}
  }
`;
