import React from 'react';
import { Context } from '@components/ContextWrapper';

import { Wrapper } from './styled';

export interface LinkProps {
  href: string;
  children: React.ReactNode;
  delay: number;
}

const Link: React.FC<LinkProps> = ({ children, href, delay }) => {
  const { menuOpen, toggleMenu } = React.useContext(Context);

  return (
    <Wrapper to={href} onAnchorLinkClick={menuOpen ? toggleMenu : undefined} delay={delay}>
      {children}
    </Wrapper>
  );
};

export default Link;
