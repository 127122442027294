import * as React from 'react';
import { Wrapper, Inner, Content, SectionBackgroundWrapper } from './styled';
import Container from '@components/Container';
import { Context } from '@components/ContextWrapper';

export type RenderTypes = 'section' | 'header' | 'footer';

export type ColorTypes = 'yellow' | 'blue' | 'black' | 'white';

interface SectionProps {
  children: React.ReactNode;
  renderAs?: RenderTypes;
  color?: ColorTypes;
  className?: string;
  id?: string;
  fullScreen?: boolean;
}

const getIconColor = (color?: string) =>
  color === 'blue' || color === 'black' ? 'white' : 'black';

const Section: React.FC<SectionProps> = ({
  renderAs = 'section',
  className,
  color = 'yellow',
  fullScreen = true,
  children,
  id,
}) => {
  const { updateHeaderColor, updateHeaderIconColor } = React.useContext(Context);
  const [endWrapperHeight, setEndWrapperHeight] = React.useState<number>();
  const sectionWrapperRef = React.useRef(null);

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = () => {
    const boundingClientRect = sectionWrapperRef.current.getBoundingClientRect();
    const headerHeight = 120;
    const top = boundingClientRect.top;
    const bottom = boundingClientRect.bottom;
    const isHeaderInsideSection = top <= headerHeight && bottom > 0;

    if (!isHeaderInsideSection) {
      return;
    }

    const isSectionTopBelowHeaderCenter = top <= headerHeight / 2;
    const isSectionBottomAboveHeader = top + boundingClientRect.height <= headerHeight;

    if (isSectionTopBelowHeaderCenter) {
      updateHeaderIconColor(getIconColor(color));
    }

    if (isSectionBottomAboveHeader) {
      setEndWrapperHeight(boundingClientRect.height);
    } else {
      updateHeaderColor(color);
      setEndWrapperHeight(0);
    }
  };

  return (
    <Wrapper
      ref={sectionWrapperRef}
      as={renderAs}
      color={color}
      className={className}
      id={id}
      fullScreen={fullScreen}
    >
      <Inner>
        <Container>
          <Content>{children}</Content>
        </Container>
      </Inner>
      {/*Absolute background for header with z-index: 30 - appears on section scroll*/}
      <SectionBackgroundWrapper color={color} height={endWrapperHeight} />
    </Wrapper>
  );
};

export default Section;
