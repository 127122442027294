import tw, { styled } from 'twin.macro';

interface LogoProps {
  color: string;
}

const handleColor = (color: string) => {
  switch (color) {
    case 'white':
      return tw`bg-white`;
    case 'black':
      return tw`bg-black`;
  }
};

export const StyledLogo = tw.div`relative flex`;

export const StyledCircle = styled.div<LogoProps>`
  ${tw`h-4 w-4`};
  ${({ color }) => handleColor(color)};
  clip-path: circle(49% at 50% 50%);
`;

export const StyledSquare = styled.div<LogoProps>`
  ${tw`h-4 w-4 mr-3`};
  ${({ color }) => handleColor(color)};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
`;

export const StyledRectangle = styled.div<LogoProps>`
  ${tw`h-4 w-4 mr-3`};
  ${({ color }) => handleColor(color)};
  clip-path: polygon(0 25%, 100% 25%, 100% 75%, 0 75%);
`;

export const StyledTriangle = styled.div<LogoProps>`
  ${tw`h-4 w-4 mr-3`};
  ${({ color }) => handleColor(color)};
  clip-path: polygon(0 0, 100% 50%, 100% 50%, 0 100%);
`;
