import tw, { styled } from 'twin.macro';

import { allowedHeadings } from './';

interface HeadingProps {
  color: string;
  withDot: boolean;
}

const handleColor = (color: string) => {
  switch (color) {
    case 'white':
      return tw`text-white`;
    case 'black':
      return tw`text-black`;
    case 'yellow':
      return tw`text-yellow`;
    case 'blue':
      return tw`text-indigo`;
  }
};

const handleBackgroundColor = (color: string) => {
  switch (color) {
    case 'white':
      return tw`bg-white`;
    case 'black':
      return tw`bg-black`;
    case 'yellow':
      return tw`bg-yellow`;
    case 'blue':
      return tw`bg-indigo`;
  }
};

const assignSize = (as: allowedHeadings) => {
  switch (as) {
    case 'h1':
      return tw`font-sans font-black text-4xl sm:text-5xl md:text-6xl lg:text-7xl max-w-3xl`;
    case 'h2':
      return tw`text-2xl sm:text-3xl md:text-4xl`;
    case 'h3':
      return tw`text-xl sm:text-2xl md:text-3xl lg:text-4xl`;
  }
};

const assignTextTransform = (as: allowedHeadings) => {
  switch (as) {
    case 'h1':
      return tw`uppercase`;
    case 'h2':
      return tw`uppercase`;
    case 'h3':
      return tw``;
  }
};

const assignPadding = (as: allowedHeadings) => {
  switch (as) {
    case 'h1':
      return tw`p-0`;
    case 'h2':
      return tw`p-1`;
    case 'h3':
      return tw`p-0`;
  }
};

export const StyledHeading = styled.span<HeadingProps>`
  ${tw`font-sans font-bold inline-block relative`};
  ${({ as }) => assignSize(as)}
  ${({ color }) => handleColor(color)};
  ${({ as }) => assignTextTransform(as)};

  ${({ as }) =>
    as === 'h1' &&
    `
    line-height: 1.35 !important;
    transform: translateY(-9px);

    @media only screen and (min-width: 1050px) {
      line-height: 1.1 !important;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 10px;
      top: -5px;
      background-color: #fff305;
    }

    @media only screen and (min-width: 1050px) {
      &:before {
        height: 18px;
        background-color: #fff305;
      }
    }

    @media only screen and (min-width: 1050px) {
      &:after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        max-width: 1049px;
        height: 2px;
        bottom: 75px;
        background-color: #fff305; 
      }
    }
  `}
`;

export const Content = styled.span<HeadingProps>`
  ${({ backgroundColor }) => handleBackgroundColor(backgroundColor)};
  ${({ size }) => assignPadding(size)};
  line-height: 1.1;
  box-decoration-break: clone;

  ${({ size }) =>
    size === 'h2' &&
    `
      display: inline-block;
      line-height: 0.85 !important;
      padding: 0 0 0.15em 0 !important;
    `}
`;
