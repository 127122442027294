import tw, { styled } from 'twin.macro';
import { getTwStyleForColor } from '@utils/';

import { ColorTypes } from './';

interface WrapperProps {
  color: ColorTypes;
}

interface SectionBackgroundWrapperProps {
  height?: number;
  color?: string;
}

const assignStyles = (color: ColorTypes) => {
  switch (color) {
    case 'yellow':
      return tw`bg-yellow text-black`;
    case 'blue':
      return tw`bg-indigo text-white`;
    case 'black':
      return tw`bg-black text-white`;
    case 'white':
      return tw`bg-white text-black`;
    default:
      return tw`text-black`;
  }
};

export const Wrapper = styled.section<WrapperProps>`
  ${tw`flex relative`};
  padding-top: 100px;
  padding-bottom: 100px;
  ${({ color }) => assignStyles(color)}
  ${({ fullScreen }) => fullScreen && tw`full-section:min-h-screen`}
`;

export const Inner = tw.div`flex-1 w-full h-full`;

export const Content = tw.div`h-full lg:flex lg:flex-col full-section:justify-between`;

export const SectionBackgroundWrapper = styled.div<SectionBackgroundWrapperProps>`
  ${tw`absolute bottom-0 w-full z-30`};
  height: ${({ height }) => height}px;
  ${({ color }) => getTwStyleForColor(color)}
`;
