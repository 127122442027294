import 'twin.macro';
import React from 'react';
import { nanoid } from 'nanoid';
import Content from '@components/Content';
import Section from '@components/Section';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Heading from '@components/Heading';
import Grid from '@components/Grid';
import { FooterColumn } from '@components/Column';

export interface FooterProps {
  heading: string;
  content: string;
  columns: Array<FooterColumnProps>;
}

export interface FooterColumnProps {
  name: string;
  content?: string;
  logos?: {
    name: string;
  }[];
  featured_contact: string;
  contact_highlight: boolean;
}

const Footer: React.FC<FooterProps> = ({ heading, content, columns }) => (
  <Section color="blue" id="contact">
    <div>
      <Heading renderAs="h2" color="blue" backgroundColor="white">
        {heading}
      </Heading>
      <div tw="py-10">
        <Content>{content}</Content>
      </div>
    </div>
    <Grid>
      {columns.map((col) => (
        <FooterColumn
          key={nanoid()}
          heading={col.name}
          cta={col.featured_contact}
          highlight={col.contact_highlight}
        >
          {col.content ? col.content : null}
          {col.logos &&
            col.logos.map((logo) => {
              const fluid = getImage(logo.icon);

              return fluid ? (
                <GatsbyImage
                  image={fluid}
                  alt={logo.name}
                  key={logo.name}
                  loading="lazy" // | "eager"
                />
              ) : null;
            })}
        </FooterColumn>
      ))}
    </Grid>
  </Section>
);

export default Footer;
