import 'twin.macro';
import React from 'react';
import { StyledHeading, Content } from './styled';

export type allowedHeadings = 'h1' | 'h2' | 'h3';
export type allowedColors = 'white' | 'black' | 'yellow';
export type allowedBackgroundColors = 'white' | 'black' | 'yellow' | 'blue' | 'none';

interface HeadingProps {
  withDot?: boolean;
  renderAs?: allowedHeadings;
  color?: allowedColors;
  backgroundColor?: allowedBackgroundColors;
  children: React.ReactNode;
}

const Heading: React.FC<HeadingProps> = ({
  renderAs = 'h1',
  color = 'black',
  backgroundColor = 'none',
  children,
}) => (
  <StyledHeading as={renderAs} color={color} backgroundColor={backgroundColor}>
    <Content size={renderAs} backgroundColor={backgroundColor}>
      {children}
    </Content>
  </StyledHeading>
);

export default Heading;
