import * as React from 'react';
import Heading from '../shared/Heading';
import { Wrapper, Icon, Subheading } from '../Solution/styled';

import Content from '../../Content';

interface SolutionProps {
  icon?: string;
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

const Solution: React.FunctionComponent<SolutionProps> = (props) => (
  <Wrapper>
    {props.icon && <Icon src={props.icon} alt="" />}
    <div>
      <Heading type="solution">{props.title}</Heading>
      <Subheading>{props.subtitle}</Subheading>
      <Content>{props.children}</Content>
    </div>
  </Wrapper>
);

export default Solution;
