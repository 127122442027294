import React, { useContext } from 'react';
import { GlobalStyles as TailwindStyles } from 'twin.macro';

import Navigation, { NavigationProps } from '@components/Navigation';
import SEO from '@components/SEO';
import { Context } from '@components/ContextWrapper';
import Footer, { FooterProps } from '@components/Footer';
import { CookiesProvider } from 'react-cookie';
import { OverflowWrapper } from './styled';

interface LayoutProps {
  title: string;
  description: string;
  lang: string;
  footer: FooterProps;
  navigation: NavigationProps;
  noIndex: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  title,
  description,
  children,
  lang,
  footer,
  navigation,
  noIndex = false,
}) => {
  const { contactModalOpen } = useContext(Context);

  return (
    <>
      <TailwindStyles />
      <SEO title={title} description={description} lang={lang} noIndex={noIndex} />
      <CookiesProvider>
        <OverflowWrapper contactModalOpen={contactModalOpen}>
          <Navigation data={navigation} />
          {children}
          <Footer heading={footer.heading} content={footer.content} columns={footer.columns} />
        </OverflowWrapper>
      </CookiesProvider>
    </>
  );
};

export default Layout;
