import * as React from 'react';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title: string;
  description: string;
  lang: string;
  noIndex: boolean;
}

const SEO: React.FC<SeoProps> = (props) => (
  <Helmet>
    <html lang={props.lang} />
    <meta charSet="utf-8" />
    {props.noIndex && <meta name="robots" content="noindex" />}
    <title>{props.title}</title>
    <meta name="description" content={props.description} />
  </Helmet>
);

export default SEO;
