import tw, { styled } from 'twin.macro';

import Container from '@components/Container';

interface MenuProps {
  visible: boolean;
}

export const MenuWrapper = styled.div<MenuProps>`
  ${tw`z-20 fixed w-screen h-screen top-0 right-0 bg-indigo text-white opacity-90`}
  /* Animated circle fill*/
  animation: circle-fill 0.6s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  @keyframes circle-fill {
    0% {
      ${tw`absolute w-0	h-0 mt-0 mr-0 rounded-full top-6 right-6`}
    }
    10% {
      ${tw`absolute w-0	h-0 mt-0 mr-0 rounded-full top-6 right-6`}
    }
    100% {
      ${tw`absolute rounded-full top-6 right-6`}
      width: 6000px;
      height: 6000px;
      margin-top: -3000px;
      margin-right: -3000px;
    }
  }
  ${({ visible }) => (visible ? tw`block` : tw`hidden`)}
`;

export const MenuInner = styled(Container)`
  ${tw`flex flex-col items-center pr-8 lg:items-end text-white pt-12 pr-16`}
`;

export const LinkCol = tw.div`flex flex-col`;

export const SocialLinks = styled.div`
  @keyframes slide-in {
    0% {
      ${tw`opacity-0`}
    }
    100% {
      ${tw`opacity-100`}
      transform: translateY(0%);
    }
  }
  transform: translateY(100%);
  animation: slide-in 1s forwards;
  animation-delay: 1s;
  display: grid;
  opacity: 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 3rem;
  padding-bottom: 3rem;
`;
export const MenuInnerWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  height: calc(100vh - 260px);
  @media only screen and (min-width: 640px) {
    grid-template-rows: auto 40px;
  }
`;
