import tw, { styled } from 'twin.macro';
import { HeadingTypes } from './index';

interface HeadingProps {
  inView: boolean;
  type: HeadingTypes;
}

const assignStyles = (type: HeadingTypes) => {
  switch (type) {
    case 'service':
      return tw`bg-black top-7 h-2`;
    case 'solution':
      return tw`bg-yellow top-3 h-4`;
    default:
      return tw`bg-black top-6 h-2`;
  }
};

const sharedStyles = tw`absolute left-0 transition-all duration-1000`;

export const StyledHeading = styled.h3<HeadingProps>`
  ${tw`font-sans font-bold text-xl relative z-10 mb-7 pr-6`}
  width: fit-content;
  &:after {
    content: "";
    z-index: -5;
    ${sharedStyles}
    ${({ type }) => assignStyles(type)}
    ${({ inView }) => (inView ? tw`w-full` : tw`w-0`)}}
  }
`;
