import React from 'react';
import { StyledLogo, StyledCircle, StyledSquare, StyledRectangle, StyledTriangle } from './styled';

export type allowedColors = 'white' | 'black';

interface LogoProps {
  color?: allowedColors;
}

const Logo: React.FC<LogoProps> = ({ color = 'black' }) => {
  return (
    <StyledLogo>
      <StyledSquare color={color} />
      <StyledRectangle color={color} />
      <StyledTriangle color={color} />
      <StyledCircle color={color} />
    </StyledLogo>
  );
};

export default Logo;
